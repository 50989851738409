/* eslint-disable jsx-a11y/anchor-is-valid */
import trainer_video from "../../assets/images/video/into-video.mp4";
import poster from "../../assets/images/video/poster.png";

function Section_video() {
  return (
    <>
      <section className="section-video pb-0">
        <div className="s-container text-center">
          <video controls poster={poster} >
            <source src={trainer_video} type="video/mp4" />
          </video>
        </div>
      </section>

      <section className="section-video-description">
        <div className="s-container text-center">
          <h2>
            Learn exactly what it takes to crack the PTE (Academic) with 79+ and
            nothing else
          </h2>

          <a href="#" target="_blank" className="cta">
            Join my 2-hour workshop and ace your PTE exam at ₹499 Only/-
            <i className="fa-regular fa-arrow-right"></i>
          </a>
        </div>
      </section>
    </>
  );
}

export default Section_video;
