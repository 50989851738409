/* eslint-disable no-script-url */
import React from "react";

import faqs_list from "./faq_list";
import FaqComponent from "./FaqComponent";

/* eslint-disable jsx-a11y/anchor-is-valid */
function Faq() {

  // const options = {
  //   key: "rzp_test_HJG5Rtuy8Xh2NB",
  //   amount: "49900", //  = INR 1
  //   name: "Serendi - PTE | PTE Academi Class",
  //   description: "some description",
  //   image: "",
  //   handler: function (response) {
  //     alert(response.razorpay_payment_id);
  //   },
  //   // prefill: {
  //   //   name: "",
  //   //   contact: "",
  //   //   email: ""
  //   // },
  //   // notes: {
  //   //   address: ""
  //   // },
  //   theme: {
  //     color: "#a6ef67",
  //     hide_topbar: false
  //   }
  // };

  // const openPayModal = options => {
  //   var rzp1 = new window.Razorpay(options);
  //   rzp1.open();
  // };
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://checkout.razorpay.com/v1/checkout.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // }, []);

  return (
    <>
      <section className="section-faq">
        <div className="s-container">
          <h1 className="title-small text-uppercase  mb-5">
            FREQUENTLY ASKED <span className="text-green">QUESTIONS</span>
          </h1>

          {faqs_list.map((faq) => {
            return <FaqComponent key={faq.id} {...faq} />;
          })}

          <a href="#" className="cta mt-5">
            Don’t miss out this course! Book now
            <i className="fa-regular fa-arrow-right"></i>
          </a>
        </div>
      </section>
    </>
  );
}

export default Faq;
