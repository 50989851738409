import Carousel from "react-elastic-carousel";

/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 576, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 992, itemsToShow: 3 },
  { width: 1200, itemsToShow: 3 },
];

function Video_testimonials() {
  return (
    <>
      <section className="video-testimonials">
        <div className="s-container">
          <h1 className="title text-uppercase">
            We are known for our{" "}
            <span className="text-green">student’s success.</span>
          </h1>

          <Carousel breakPoints={breakPoints} className="mt-5">
            <div className="item m-2">
              <iframe src="https://www.youtube.com/embed/Z6JGqt_ufg0?autoplay=0&mute=1"></iframe>
            </div>
            <div className="item m-2">
              <iframe src="https://www.youtube.com/embed/J-8TuhEVKLY?autoplay=0&mute=1"></iframe>
            </div>
            <div className="item m-2">
              <iframe src="https://www.youtube.com/embed/Mlr5vTs_glQ?autoplay=0&mute=1"></iframe>
            </div>
            <div className="item m-2">
              <iframe src="https://www.youtube.com/embed/I9DnvpWeBfk?autoplay=0&mute=1"></iframe>
            </div>
            <div className="item m-2">
              <iframe src="https://www.youtube.com/embed/SWbN7h3ofKs?autoplay=0&mute=1"></iframe>
            </div>
          </Carousel>

          <a
            href=""
            target="_blank"
            rel="noopener noreferrer"
            className="cta mt-5"
          >
            Enroll Now
            <i className="fa-regular fa-arrow-right"></i>
          </a>
        </div>
      </section>
    </>
  );
}

export default Video_testimonials;
