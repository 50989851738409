const faq = [
    {
        id: 1,
        title: "What is PTE academic?",
        info: "PTE Academic is a 2 hour long, computer-based assessment of a person’s English language ability in an academic context. The test assesses an individual’s communicative skills of Reading, Writing, Listening and Speaking skills through questions using authentically-sourced material. In addition, the test provides feedback on enabling skills in the form of Oral Fluency, Grammar, Vocabulary, Written Discourse, Pronunciation and Spelling.",
    },
    {
        id: 2,
        title: "How is PTE Academic scored?",
        info: "PTE Academic is assessed using automated scoring technology and test takers are provided with a score from 10-90 overall and across all skills. Pearson’s 10-90 scale is based on the Global Scale of English.",
    },
    {
        id: 3,
        title: "How much does the PTE academic cost?",
        info: "The fee for the PTE Test is set to rise from $375.00 to $385.00 effective 4 January 2022. All tests booked prior to 3 January 2022 – regardless of test date – will be charged $375.00. All tests booked after 3 January 2022 will be charged $385.00.",
    },
    {
        id: 4,
        title: "Is PTE Academic recognised by Department of Immigration and Boarder Protection (DIBP)?",
        info: "Yes. PTE Academic is one of the tests accepted by DIBP, for more information visit DIBP",
    },
    {
        id: 5,
        title: "How can I prepare for the PTE test?",
        info: "One of the best ways to prepare for the PTE test is to take PTE course from us. This will provide you with the necessary skills and knowledge to succeed on the test. Additionally, you should practice with PTE practice tests and to build your confidence and improve your score.",
    },
    {
        id: 6,
        title: "What are the minimum PTE test scores required by universities and colleges?",
        info: "The minimum PTE test scores required by universities and colleges vary, so it is important to check with the institution you are applying to. However, most universities and colleges require a score of at least 50-60 for undergraduate programs and 60-70 for graduate programs.",
    },
    {
        id: 7,
        title: "How long are the PTE test results valid?",
        info: "Don’t miss out this course! Book now",
    },
];
export default faq;
