/* eslint-disable jsx-a11y/anchor-is-valid */
import Trainer_1 from "../../assets/images/trainer_1.jpg";

function Your_mentor() {
  return (
    <>
      <section className="section-your-mentor">
        <div className="s-container">
          <h1 className="title text-uppercase">
            Your mentor, <span className="text-green">SIDHANT</span>
          </h1>

          <div className="row align-items-center mt-5">
            <div className="col-md-5 text-center">
              <img src={Trainer_1} alt="" className="img-fluid rounded" />
            </div>
            <div className="col-md-7 mentor-details">
              <h1 className="mentor-name text-green">SIDHANT KHANNA</h1>
              <h4>PTE Academic Trainer</h4>

              <p className="mentor-description mt-5">
                Experienced PTE Academic trainer with a successful institute in
                Melbourne for the past 5 years, now offering online classes to
                students globally. My courses include personal encounters with
                the PTE Academic test, a mountain of experience, and strategies
                and techniques to successfully crack the test. I promise to make
                the learning experience fun, to the point, and value-oriented,
                and will strive to ease the burden of crossing this small yet
                significant hurdle in your life.
              </p>
            </div>
          </div>

          <a href="#" target="_blank" className="cta mt-5">
            Join me on this workshop to achieve your PTE Academic goals!
            <i className="fa-regular fa-arrow-right"></i>
          </a>
        </div>
      </section>
    </>
  );
}

export default Your_mentor;
