import students from "../../assets/images/icons/students.png";
import result from "../../assets/images/icons/result.png";
import trainer from "../../assets/images/trainer.png";

function Section_main() {
  return (
    <>
      <section className="main">
        <div className="s-container">
          <div className="content-main text-center">
            <h1 className="title text-uppercase">
              PTE Crash Course<br></br>The Secret Weapon to Your Success
              <span className="text-green">.</span>
            </h1>

            <h3 className="title-sub">2 Hours Live Class</h3>

            <p className="description">
              By someone who has scored a perfect 90 in PTE (Academic) over 10
              times in a row and trained 2000+ students globally.
            </p>

            <h3>MEET YOUR TRAINER</h3>
            <div className="d-flex justify-content-center">
              <div className="col-md-8">
                <div className="row align-items-center trainer">
                  <div className="col-md-5 text-center">
                    <img src={trainer} alt="" />
                  </div>
                  <div className="col-md-7">
                    <div className="details">
                      <h4>SIDHANT KHANNA</h4>
                      <h5 className="mt-2">Serendi PTE</h5>

                      <div className="row mt-4">
                        <div className="col-6">
                          <div className="item">
                            <img src={students} alt="" />
                            <h5 className="mt-2">2000+</h5>
                            <h6>Students Trained</h6>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="item">
                            <img src={result} alt="" />
                            <h5 className="mt-2">700+</h5>
                            <h6>Desired Result</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Section_main;
