import React, { useState } from "react";

const FaqComponent = ({ title, info }) => {
    const [showInfo, setShowInfo] = useState(false);

    return (
        <>
            <div className="faq-item mb-4">
                <div className="faq-title" onClick={() => setShowInfo(!showInfo)}>
                    {title}
                    {showInfo ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
                </div>
                {showInfo &&
                    <div className="">
                        <p>{info}</p>
                    </div>
                }
            </div>
        </>
    );
};

export default FaqComponent;
