/* eslint-disable react/jsx-pascal-case */
import Section_main from "./components/Section_main/Section_main";
import Section_video from "./components/Section_video/Section_video";
import Section_video_testimonial from "./components/Video_testimonials/Video_testimonials";
import Section_insight from "./components/Section_insight/Section_insight";
import Section_udemy from "./components/Section_udemy/Section_udemy";
import Course_for from "./components/Course_for/Course_for";
import Result_90 from "./components/Result_90/Result_90";
import Your_mentor from "./components/Your_mentor/Your_mentor";
import Course_description from "./components/Course_description/Course_description";
import Student_success from "./components/Student_success/Student_success";
import Faq from "./components/Faq/Faq";
import ScrollTop from "./components/ScrollTop";

function App() {
  return (
    <div className="App">
      <Section_main />
      <Section_video />
      <Section_video_testimonial />
      <Section_insight />
      <Section_udemy />
      <Course_for />
      <Result_90 />
      <Your_mentor />
      <Course_description />
      <Student_success />
      <Faq />
      <ScrollTop />
    </div>
  );
}

export default App;
