/* eslint-disable jsx-a11y/anchor-is-valid */
function Section_insight() {
  return (
    <>
      <section className="session-insight">
        <div className="s-container">
          <h1 className="title text-uppercase">
            Here’s what you will learn in 2 hours of{" "}
            <span className="text-green">insightful session</span>
          </h1>

          <div className="row mt-5">
            <div className="col-md-6 insight-point">
              <ul>
                <li>
                  <i className="fa-regular fa-check"></i>How to score 79+ in
                  PTE.
                </li>
                <li>
                  <i className="fa-regular fa-check"></i>Coaching for All
                  Modules of PTE.
                </li>
                <li>
                  <i className="fa-regular fa-check"></i>English Proficiency –
                  Reading Module | Speaking Module | Writing Module | Listening
                  Module
                </li>
                <li>
                  <i className="fa-regular fa-check"></i>Learn all HACKS &
                  Strategies of PTE, based on real experiments.
                </li>
                <li>
                  <i className="fa-regular fa-check"></i>Ways to beat the PTE
                  algorithm.
                </li>
              </ul>
            </div>
            <div className="col-md-6 insight-point">
              <ul>
                <li>
                  <i className="fa-regular fa-check"></i>A real-life breakdown
                  of the structure and marking criteria of the PTE Academic
                  test.
                </li>
                <li>
                  <i className="fa-regular fa-check"></i>Construction of scores
                  in each module
                </li>
                <li>
                  <i className="fa-regular fa-check"></i>Questions to skip.
                </li>
                <li>
                  <i className="fa-regular fa-check"></i>Most valuable question
                  types.
                </li>
                <li>
                  <i className="fa-regular fa-check"></i>How silly PTE really
                  is.
                </li>
              </ul>
            </div>
          </div>

          <a
            href=""
            target="_blank"
            rel="noopener noreferrer"
            className="cta mt-5"
          >
            Kick Start Your PTE Journey at ₹499 Only/-
            <i className="fa-regular fa-arrow-right"></i>
          </a>
        </div>
      </section>
    </>
  );
}

export default Section_insight;
