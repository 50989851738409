/* eslint-disable no-script-url */
import { useEffect, useState } from 'react';


/* eslint-disable jsx-a11y/anchor-is-valid */
function ScrollTop() {
    const date = new Date();
    let next_date = date.getDate() + 1;

    const calculateTimeLeft = () => {
        const difference = +new Date("2023-02-" + next_date + "T00:00:00+05:30") - +new Date();

        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                hours: Math.floor(difference / (1000 * 60 * 60)),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const [scrollValue, setScrollValue] = useState(0);

    useEffect(() => {
        const onScroll = (e) => {
            setScrollValue(e.target.documentElement.scrollTop);
        };
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [scrollValue]);

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });


    return (
        <>
            {
                scrollValue >= 100 ?
                    <div className="cta-bottom">
                        <div className='price'>
                            ₹499
                            <sub><strike>₹999</strike></sub>
                            <br></br>
                            <h5><b>2000+ Enrolled</b></h5>
                        </div>
                        <div className='cta-tm-btn'>
                            <div className='timer text-center mb-1'>
                                Ends in {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s.
                            </div>
                            <a href="#" className="cta-bottom-btn">
                                Enroll Now
                            </a>
                        </div>
                    </div>
                    : ""
            }
        </>
    );
}

export default ScrollTop;