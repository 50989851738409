import Result_1 from "../../assets/images/result_90/Result 1.jpg";
import Result_2 from "../../assets/images/result_90/Result 3.jpg";
import Result_3 from "../../assets/images/result_90/Result 3.jpg";
import Result_4 from "../../assets/images/result_90/Result 4.jpg";
import Result_5 from "../../assets/images/result_90/Result 5.jpg";
import Result_6 from "../../assets/images/result_90/Result 6.jpg";

import Carousel from "react-elastic-carousel";

/* eslint-disable jsx-a11y/anchor-is-valid */

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 576, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 992, itemsToShow: 3 },
  { width: 1200, itemsToShow: 3 },
];

function Result_90() {
  return (
    <>
      <section className="section-result-90">
        <div className="s-container">
          <h1 className="title text-uppercase">
            Experiment <span className="text-green">90-90-90-90</span> On PTE by
            SID
          </h1>

          <Carousel breakPoints={breakPoints} className="mt-5">
            <div className="item m-2">
              <img src={Result_1} alt="" className="img-fluid rounded" />
            </div>
            <div className="item m-2">
              <img src={Result_2} alt="" className="img-fluid rounded" />
            </div>
            <div className="item m-2">
              <img src={Result_3} alt="" className="img-fluid rounded" />
            </div>
            <div className="item m-2">
              <img src={Result_4} alt="" className="img-fluid rounded" />
            </div>
            <div className="item m-2">
              <img src={Result_5} alt="" className="img-fluid rounded" />
            </div>
            <div className="item m-2">
              <img src={Result_6} alt="" className="img-fluid rounded" />
            </div>
          </Carousel>

          <a href="" target="_blank" className="cta mt-5">
            Come on! FOD DO PTE!
            <i className="fa-regular fa-arrow-right"></i>
          </a>
        </div>
      </section>
    </>
  );
}

export default Result_90;
