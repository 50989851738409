/* eslint-disable jsx-a11y/anchor-is-valid */
function Course_for() {
  return (
    <>
      <section className="section-course-for">
        <div className="s-container">
          <h1 className="title text-uppercase">
            Who is this <span className="text-green">course</span> for ?
          </h1>

          <ul className="course-for mt-5">
            <li>
              <span>1.</span> Ideal for individuals who are preparing to take
              the PTE (Pearson Test of English) exam.
            </li>
            <li>
              <span>2.</span> The course is designed for students,
              professionals, and immigrants who need to demonstrate their
              English proficiency for academic or professional purposes.
            </li>
            <li>
              <span>3.</span> It is highly recommended for non-native English
              speakers who want to improve their language skills and achieve a
              higher score on the PTE exam.
            </li>
            <li>
              <span>4.</span> It is most suitable for people who want to study
              or work in an English-speaking country and need to pass the PTE
              exam as a requirement.
            </li>
            <li>
              <span>5.</span> The course is tailored for individuals who want to
              improve their listening, speaking, reading, and writing skills in
              English.
            </li>
            <li>
              <span>6.</span> It is also suitable for people who want to improve
              their grammar, vocabulary, and pronunciation in English.
            </li>
            <li>
              <span>7.</span> The course is designed for learners of all levels,
              from beginner to advanced, and is tailored to meet the needs of
              every student.
            </li>
          </ul>

          <a href="" target="_blank" className="cta mt-5">
            Click here to Get PTE-ready in record time!
            <i className="fa-regular fa-arrow-right"></i>
          </a>
        </div>
      </section>
    </>
  );
}

export default Course_for;
