import Result_1 from "../../assets/images/student_result/Result 1.jpg";
import Result_2 from "../../assets/images/student_result/Result 2.jpg";
import Result_3 from "../../assets/images/student_result/Result 3.jpg";
import Result_4 from "../../assets/images/student_result/Result 4.jpg";
import Result_5 from "../../assets/images/student_result/Result 5.jpg";
import Result_6 from "../../assets/images/student_result/Result 6.jpg";
import Result_7 from "../../assets/images/student_result/Result 7.jpg";
import Result_8 from "../../assets/images/student_result/Result 8.jpg";
import Result_9 from "../../assets/images/student_result/Result 9.jpg";
import Result_10 from "../../assets/images/student_result/Result 10.jpg";

import Carousel from "react-elastic-carousel";

/* eslint-disable jsx-a11y/anchor-is-valid */

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 576, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 992, itemsToShow: 3 },
  { width: 1200, itemsToShow: 3 },
];

function Student_success() {
  return (
    <>
      <section className="student-success">
        <div className="s-container">
          <h1 className="title text-uppercase">
            Student <span className="text-green">success</span> is our greatest{" "}
            <span className="text-green">accomplishment</span>
          </h1>

          <Carousel breakPoints={breakPoints} className="mt-5">
            <div className="item m-2">
              <img src={Result_1} alt="" className="img-fluid rounded" />
            </div>
            <div className="item m-2">
              <img src={Result_2} alt="" className="img-fluid rounded" />
            </div>
            <div className="item m-2">
              <img src={Result_3} alt="" className="img-fluid rounded" />
            </div>
            <div className="item m-2">
              <img src={Result_4} alt="" className="img-fluid rounded" />
            </div>
            <div className="item m-2">
              <img src={Result_5} alt="" className="img-fluid rounded" />
            </div>
            <div className="item m-2">
              <img src={Result_6} alt="" className="img-fluid rounded" />
            </div>
            <div className="item m-2">
              <img src={Result_7} alt="" className="img-fluid rounded" />
            </div>
            <div className="item m-2">
              <img src={Result_8} alt="" className="img-fluid rounded" />
            </div>
            <div className="item m-2">
              <img src={Result_9} alt="" className="img-fluid rounded" />
            </div>
            <div className="item m-2">
              <img src={Result_10} alt="" className="img-fluid rounded" />
            </div>
          </Carousel>

          <a href="" target="_blank" className="cta mt-5">
            Reserve your spot now. Limited seats!
            <i className="fa-regular fa-arrow-right"></i>
          </a>
        </div>
      </section>
    </>
  );
}

export default Student_success;
