/* eslint-disable jsx-a11y/anchor-is-valid */
function Course_description() {
  return (
    <>
      <section className="section-course-d">
        <div className="s-container">
          <h1 className="title-small text-uppercase">
            Course <span className="text-green">Description</span>
          </h1>

          <ul className="course-description mt-5">
            <li>
              <span>1.</span> This course offers a comprehensive guide for those
              preparing for the PTE-Academic test.
            </li>
            <li>
              <span>2.</span> The course includes all PTE hacks and tricks based
              on 14 real-exam experiments and over 5 years of teaching
              experience.
            </li>
            <li>
              <span>3.</span> Best strategies used by hundreds of successful
              students.
            </li>
            <li>
              <span>4.</span> The course promises to educate you with all the
              essential knowledge and provide all the relevant resources you
              need to crack the PTE-Academic test in just 2 hours.
            </li>
            <li>
              <span>5.</span> The course is affordable, costing the same as a
              nice meal in an Indian restaurant (₹499 only).
            </li>
            <li>
              <span>6.</span> It is perfect for those who want to go over the
              course structure 2 days before their test and who have a solid
              understanding of English language fundamentals but just need to
              get acquainted with PTE.
            </li>
            <li>
              <span>7.</span> It is also ideal for those who are tired of
              preparing from YouTube with no sense of security about what works
              and what doesn’t and who are very close to their scores and don’t
              want to spend hundreds of dollars on PTE coaching.
            </li>
          </ul>

          <a href="#" target="_blank" className="cta mt-5">
            Ready, set, reserve now and conquer the test!
            <i className="fa-regular fa-arrow-right"></i>
          </a>
        </div>
      </section>
    </>
  );
}

export default Course_description;
