import udemy from "../../assets/images/udemy.png";
import online_course from "../../assets/images/icons/online-course.png";
import review from "../../assets/images/icons/review.png";
import score from "../../assets/images/icons/score.png";
import review1 from "../../assets/images/udemy_review/review_1.png";
import review2 from "../../assets/images/udemy_review/review_2.png";
import review3 from "../../assets/images/udemy_review/review_3.png";
import review4 from "../../assets/images/udemy_review/review_4.png";

import Carousel from "react-elastic-carousel";

/* eslint-disable jsx-a11y/anchor-is-valid */

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 576, itemsToShow: 1 },
  { width: 768, itemsToShow: 2 },
  { width: 992, itemsToShow: 2 },
  { width: 1200, itemsToShow: 2 },
];

/* eslint-disable jsx-a11y/anchor-is-valid */
function Section_udemy() {
  return (
    <>
      <section className="section-udemy">
        <div className="s-container">
          <h1 className="title text-uppercase">
            4.5 Ratings from <span className="text-green">320+ students</span>{" "}
            on <span className="text-green">Udemy</span>
          </h1>

          <img src={udemy} alt="" className="img-fluid rounded mt-5" />

          <div className="row pt-5 pb-5">
            <div className="col-sm-12 col-md-4 mb-3 mb-md-0">
              <div className="item text-center">
                <img src={online_course} alt="" className="icon" />
                <h2 className="mt-2 mb-3">
                  2000 <span className="text-green">+</span>
                </h2>
                <h5 className="sub-title">Cureent Student Enrolled</h5>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 mb-3 mb-md-0">
              <div className="item text-center">
                <img src={review} alt="" className="icon" />
                <h2 className="mt-2 mb-3">
                  4.5 <i className="fa-solid fa-star"></i>
                </h2>
                <h5 className="sub-title">Rating</h5>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 mb-3 mb-md-0">
              <div className="item text-center">
                <img src={score} alt="" className="icon" />
                <h2 className="mt-2 mb-3">
                  700 <span className="text-green">+</span>
                </h2>
                <h5 className="sub-title">Desired Scores Achived</h5>
              </div>
            </div>
          </div>

          <div className="row pt-5 pb-5">
            <div className="col-md-12 text-center mb-2">
              <h2 className="title-small text-uppercase">
                WHAT OUR <span className="text-green">STUDENTS Say.</span>
              </h2>
            </div>

            <Carousel breakPoints={breakPoints} className="mt-2">
              <div className="m-2">
                <img
                  src={review1}
                  alt=""
                  className="img-fluid u-review rounded"
                />
              </div>
              <div className="m-2">
                <img
                  src={review2}
                  alt=""
                  className="img-fluid u-review rounded"
                />
              </div>
              <div className="m-2">
                <img
                  src={review3}
                  alt=""
                  className="img-fluid u-review rounded"
                />
              </div>
              <div className="m-2">
                <img
                  src={review4}
                  alt=""
                  className="img-fluid u-review rounded"
                />
              </div>
            </Carousel>
          </div>

          <a href="" target="_blank" rel="noopener noreferrer" className="cta">
            RESERVE YOUR SEAT NOW AT ₹499 ONLY/-
            <i className="fa-regular fa-arrow-right"></i>
          </a>
        </div>
      </section>
    </>
  );
}

export default Section_udemy;
